import FindKey from '../quizzes/FindKey'
import Raw from '../quizzes/Raw'
import SQL from '../quizzes/SQL'
import Python from '../quizzes/Python'
// import Markdown from '../quizzes/Markdown'
import { keyToID } from '../lib/ObjectUtil'
import quizDAG from '../datas/quiz.json'
import { getJSON } from '@rabbotio/fetcher'

const parser = async quizData => {
  switch (quizData.meta.parser) {
    case 'FindKey':
      return FindKey.parse(quizData)
    // case 'Markdown' : Markdown.parse(quizURL);break;
    case 'SQL':
      return SQL.parse(quizData)
    case 'PYTHON':
      return Python.parse(quizData)
    default:
      return Raw.parse(quizData)
  }
}

export default class QuizFactory {
  static async build (json, quizURL = './quiz') {
    // Quiz source
    if (!quizURL || quizURL === '') return

    // Load quiz
    const quizData = await getJSON(quizURL)
    if (!quizData) {
      throw new Error(`Not found : ${quizURL}`)
    }

    // Build quiz, solutions
    const { quiz, solutions, meta } = await parser(quizData)

    // Mutate DAG
    Object.assign(json, quizDAG, quiz)

    // console.log('json')
    // console.dir(json)

    // Apply id
    keyToID(json)

    return { nextId: 'QUIZ.BEGIN', solutions, meta }
  }
}
