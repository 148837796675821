import * as firebase from 'firebase/app'
//NOTWORK//import 'firebase/auth'
import 'firebase/firestore'

import firebaseConfig from '../firebaseConfig.dev.json'

const REGION = 'asia-east2'
const DEV_FIREBASE_URI = `http://localhost:5000/foxfox-dev/${REGION}`
const PROD_FIREBASE_URI = `https://${REGION}-foxfox-dev.cloudfunctions.net`

export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const db = firebase.firestore()
//NOTWORK//export const auth = firebase.auth()

// Firebase
const FIREBASE_URI = window.location.hostname === 'localhost' ? DEV_FIREBASE_URI : PROD_FIREBASE_URI
export const getCustomTokenURI = `${FIREBASE_URI}/getCustomToken`

// Goggle
const GOOGLE_API_URI = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty'
export const setAccountInfoURI = `${GOOGLE_API_URI}/setAccountInfo?key=${firebaseConfig.apiKey}`

export const increment = firebase.firestore.FieldValue.increment(1)
