// import q0 from './ml/binary-classifiers-outcome.json'
// import q0 from './ml/ai-vs-rule-based.json'
// import q0 from './ml/binary-classifiers-outcome-different.json'
// import q0 from './ml/automation-vs-augmentation.json'
// import q0 from './ml/automation-vs-augmentation-when.json'
// import q0 from './ml/test.json'

export default class FindKey {
  static parse = ({ questions, answers }) => {
    const checkboxes = Object.keys(answers)
    let values = []
    Object.keys(answers).forEach(key => (values = values.concat(answers[key].map(e => ({ q: e, a: key })))))

    const quiz = {}
    const solutions = []
    values.forEach(
      (e, i) =>
        (quiz[`QUIZ#${i}`] = {
          msgs: [questions[0], e.q],
          input: {
            index: i,
            jump: `QUIZ${i < values.length - 1 ? `#${i + 1}` : '.END'}`,
            checkboxes: checkboxes.map((label, j) => {
              e.a === label && (solutions[i] = [j])
              return label
            })
          }
        })
    )

    return { quiz, solutions }
  }
}
