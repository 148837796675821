import QuestModel from './QuestModel'
import ScoreModel from '../scores/ScoreModel'
import UserModel from '../model/UserModel'

import { fills, fill } from '../lib/StringUtil'

const fillResult = (msgs, { score, total, percent }) => {
  msgs = fills(msgs, 'score', score)
  msgs = fills(msgs, 'total', total)
  return fills(msgs, 'percent', percent.toFixed(2).split('.00')[0])
}

export const questResponder = (chatData, { onBeginEachTask, onRetry }) => {
  const { id } = chatData

  // QUIZ#? or GAME#?
  if (id && id.indexOf('#') !== -1) {
    // Model
    QuestModel.saveTimeRecord()

    // View
    const [action, index] = id.split('#')
    onBeginEachTask({ action, index: parseInt(index) })
    return
  }

  // Other state
  switch (id) {
    case 'SCORE':
      // Model
      QuestModel.endTimeRecord()

      // View
      const result = QuestModel.getResult()
      chatData.msgs = fillResult(chatData.msgs, result)

      // Save score
      const score = Object.assign(result, {
        uid: UserModel.firebase.uid,
        displayName: UserModel.firebase.displayName,
        photoUrl: UserModel.firebase.photoUrl,
        createdDate: new Date().toISOString()
      })

      // TODO : use reducer?
      ScoreModel.addScore(result.id, score)
      break
    case 'RETRY':
      // Clear old data
      onRetry()

      chatData.replies.forEach(e => {
        if (e.jump.indexOf('{{') > -1) {
          e.jump = fill(e.jump, 'questId', QuestModel.getQuestId())
        }
      })

      break
    default:
      // Do something?
      break
  }
}
