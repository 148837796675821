import { injectButtonEvent, injectSubmitEvent } from './RBChatButtonInjector'
import QuestModel from '../quests/QuestModel'
import { fill } from '../lib/StringUtil'

const _addQuickReplyController = (json, userId, goto) => {
  // Quick reply by user
  return (event, { label, text, value, jump }) => {
    const _replyId = `_${jump}`

    // Auto reply
    json[_replyId] = Object.assign({
      uid: userId,
      msgs: [text || label],
      jump
    })

    // TODO : clean this hack
    // Link?
    if (typeof jump === 'string' && jump.indexOf('/') === 0) {
      if (jump.indexOf('{{') > -1) {
        jump = fill(jump, 'questId', QuestModel.getQuestId())
      }
      // Bot open window
      window.open(`${window.location.origin}${jump}`)
      return
    }

    // Go!
    goto(_replyId)
  }
}

/*
const _addEmailController = (json, profile, goto, setEmail) => {
  return (event, { jump }) => {
    const { value } = event.target.elements[0]

    // TODO : Support more input type
    console.log(event.target.type)

    const _replyId = `_${jump}`

    // Auto reply
    json[_replyId] = Object.assign(
      {
        msgs: [value],
        jump: 'CONFIRM_EMAIL'
      },
      profile
    )

    // Set state
    setEmail(value)

    // Go!
    goto(_replyId)
  }
}
*/

const _addQuizController = (json, userId, goto) => {
  return (event, { index, jump, values, datas }) => {
    const _replyId = `_${jump}`
    const msg = values.length === 1 ? values[0] : values.join(',')

    // View : Auto reply
    json[_replyId] = Object.assign({
      uid: userId,
      msgs: [msg],
      jump
    })

    // Model
    QuestModel.commit(index, datas.sort())

    // Go!
    goto(_replyId)
  }
}

const addController = (userId, { json, goto, chatData }) => {
  // Inject
  chatData.replies &&
    injectButtonEvent(chatData.replies, {
      onClick: _addQuickReplyController(json, userId, goto)
    })
  // chatData.game && injectSubmitEvent(chatData.input, { onSubmit: _addQuizController(json, userId, goto) })

  if (chatData.input) {
    injectSubmitEvent(chatData.input, { onSubmit: _addQuizController(json, userId, goto) })

    chatData.input.extraButtons &&
      injectButtonEvent(chatData.input.extraButtons, {
        onClick: _addQuickReplyController(json, userId, goto)
      })
  }
}

export { addController }
