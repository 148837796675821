import _ from 'underscore'

const getGenesisQuest = () => ({
  type: null,
  id: null,
  title: null,
  resources: null,
  lastIndex: -1,
  actions: [],
  solutions: [],
  times: [],
  failures: [],
  results: [],
  habits: []
})

let _quest = getGenesisQuest()

export default class QuestModel {
  static get questPercent() {
    const questPercent = ((_quest.lastIndex + 1) / QuestModel.questTotal) * 100
    return isNaN(questPercent) ? 0 : questPercent
  }

  static get questTotal() {
    return _quest.solutions ? _quest.solutions.length : 0
  }

  static setHints = solutions => {
    _quest.solutions = solutions
  }

  // TODO : Move to lib and use at server
  // we didn't keep score directly but tend to calculate from
  // user `action` then keep `results` and calculate `score` from it.
  // In case of quiz `solutions` should expected `[0]` as $choice

  static appendAnswerResult = result => {
    _quest.results.push(result)
  }

  static saveTimeRecord = () => {
    _quest.times.push(+new Date())
  }

  static endTimeRecord = () => {
    _quest.times.push(+new Date())
  }

  static validateResult = (a, b) => {
    switch (b.type) {
      case 'full':
        return b.checksums.indexOf(a[0]) > -1
      default:
        return a.join(',') === b.join(',')
    }
  }

  static getResult = () => {
    let score = 0
    let total = QuestModel.questTotal

    _quest.actions.forEach((e, i) => {
      score += QuestModel.validateResult(e, _quest.solutions[i]) ? 1 : 0
    })

    return {
      id: _quest.id,
      type: _quest.type,
      title: _quest.title,
      level: _quest.level,
      version: _quest.version,

      score,
      total,
      percent: (100 * score) / total,

      times: _quest.times,
      time: _.last(_quest.times) - _quest.times[0],

      results: _quest.results,

      habits: _quest.habits
    }
  }

  static setResource = value => (_quest.resources = value)
  static getResource = () => _quest.resources

  static setQuestMetaData = meta => {
    _quest = { ..._quest, ...meta }
  }

  static commit = (index, datas, habits) => {
    _quest.lastIndex = index
    _quest.actions[index] = datas
    _quest.habits[index] = habits
  }

  static getQuestId = () => _quest.id

  static getLastIndex = () => _quest.lastIndex

  static getLastResult = () => {
    const i = _quest.lastIndex
    const e = _quest.actions[i]

    return QuestModel.validateResult(e, _quest.solutions[i])
  }

  static getUserAnswers = index => _quest.actions[index]

  static init = () => (_quest = getGenesisQuest())

  static reset = () => {
    _quest = {
      ..._quest,
      ...{
        lastIndex: -1,
        actions: [],
        solutions: [],
        times: [],
        failures: [],
        results: [],
        habits: []
      }
    }
  }
}
