import _ from 'underscore'

export const applyType = (_quizData, type) => {
  for (let key in _quizData) {
    let e = _quizData[key]

    // Define type
    if (e.fill) e.fill.type = type
  }
}

export const createResultFlow = (_quizData, corrects, incorrects) => {
  const lastQuizIndex = Object.keys(_quizData).length - 1
  let results = []

  for (let key in _quizData) {
    let e = _quizData[key]

    // Auto define index
    const index = parseInt(key.split(`QUIZ#`)[1])
    if (e.fill) e.fill.index = index
    if (e.full) e.full.index = index

    // Auto define jump for results
    if (corrects) {
      _quizData[`QUIZ#${index}.CORRECT`] = {
        msgs: [_.sample(corrects)],
        jump: index < lastQuizIndex ? `QUIZ#${index + 1}` : `QUIZ.END`
      }
    }
    if (incorrects) {
      _quizData[`QUIZ#${index}.INCORRECT`] = {
        msgs: [_.sample(incorrects)],
        jump: index < lastQuizIndex ? `QUIZ#${index + 1}` : `QUIZ.END`
      }
    }

    // Jumpable?
    const cursor = _quizData[`QUIZ#${index}`]
    if (!cursor) continue

    if (cursor.fill) {
      cursor.fill.jump = {
        corrects: `QUIZ#${index}.CORRECT`,
        incorrects: `QUIZ#${index}.INCORRECT`
      }
    }

    if (cursor.full) {
      cursor.full.jump = {
        corrects: `QUIZ#${index}.CORRECT`,
        incorrects: `QUIZ#${index}.INCORRECT`
      }
    }

    e.fill && results.push(e.fill.corrects)
    e.full && results.push(e.full.validation)
  }

  return results
}
