import React, { useState, useMemo, useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from './Home'
import Quest from './Quest'
import { AppContext } from './context'
import UserModel from './model/UserModel'

import './App.css'
import styled from 'styled-components'

// Firebase
import { signInWithLINEId, updateUserProfile } from './services/FirebaseAuth'
import Score from './Score'
import Aww from './Aww'

// Liff
const liff = window.liff
if (!liff) {
  console.error('No LIFF found')
}

const Allz = styled.div`
   {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
  }
`

const Loadingz = styled.div`
   {
    background: white;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      text-align: center;
    }
  }
`

const App = () => {
  let { isLocal } = useContext(AppContext)
  const [userProfile, setUserProfile] = useState(null)
  const [userStatus, setUserStatus] = useState('LINE')
  const [user, setUser] = useState(null)

  useMemo(() => {
    if (userStatus === 'LINE') {
      if (isLocal) {
        setUserProfile({ userId: 'foo-local' })
        return
      }

      if (!liff) {
        setUserProfile({ userId: 'foo-no-liff' })
        return
      }

      liff.init(
        async data => {
          if (!data) {
            setUserProfile({ userId: 'foo-no-data' })
            return
          }

          const lineUserProfile = await liff.getProfile()

          if (!lineUserProfile) {
            setUserProfile({ userId: 'foo-no-userProfile' })
            return
          }

          setUserProfile(lineUserProfile)
        },
        error => {
          if (isLocal) return
          console.error(error)

          setUserProfile({ userId: 'foo-liff-no-init' })
        }
      )
    }
  }, [isLocal, userStatus, setUserProfile])

  useEffect(() => {
    if (!userProfile) return
    setUserStatus('Firebase')

    // Keep LINE user profile
    UserModel.line = Object.assign({}, userProfile)

    // Local user
    if (UserModel.line.userId.indexOf('foo') === 0) {
      UserModel.line = {
        userId: 'X7ba0a14e906050a4b7326a034e297747',
        displayName: 'foo',
        pictureUrl: 'https://avatars1.githubusercontent.com/u/48350001?s=200'
      }
    }

    const firebaseUserProfile = {
      uid: UserModel.line.userId,
      displayName: UserModel.line.displayName,
      photoUrl: UserModel.line.pictureUrl
    }

    if (isLocal) {
      // Keep Firebase user profile
      UserModel.firebase = {
        uid: UserModel.line.userId,
        displayName: UserModel.line.displayName,
        photoUrl: UserModel.line.pictureUrl
      }

      // Uncomment this to bypass firebase login
      setUser(UserModel.line)
      return
    }

    signInWithLINEId(UserModel.line.userId).then(info => {
      // Do something with new user?
      console.log('isNewUser:', info.additionalUserInfo.isNewUser)

      // Update user profile
      updateUserProfile(firebaseUserProfile).then(response => {
        // Keep Firebase user profile
        UserModel.firebase = {
          uid: response.localId,
          displayName: response.displayName,
          photoUrl: response.photoUrl
        }
      })

      // TODO : Keep in UserModel for later use
      setUser(info.user)
    })
  }, [userProfile, isLocal])

  if (!user)
    return (
      <Loadingz>
        <div>
          <span id="wave">
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </span>
          <div>
            <img alt="kat" src="/img/duck.png" width="16px" height="16px" />
          </div>
          <small>Connecting {userStatus}</small>
        </div>
      </Loadingz>
    )

  return (
    <Allz>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/score" component={Score} />
        <Route path="/aww" component={Aww} />
        <Route path="/:section" component={Quest} />
      </Switch>
    </Allz>
  )
}

export default App
