import React from 'react'
import { version } from '../package.json'
import { isLocal } from './lib/DeveloperUtil'

const _app = {
  isLocal,
  env: 'prod',
  version
}

export const AppContext = React.createContext(_app)
