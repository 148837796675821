export default class Raw {
  static parse = quizData => {
    let solutions = []

    for (let key in quizData) {
      let e = quizData[key]

      // No Input
      if (!e.input) continue

      // Auto define index
      const index = parseInt(key.split(`QUIZ#`)[1])
      e.input.index = index

      // Auto define jump if need
      e.input.jump = e.input.jump || `QUIZ#${index + 1}`

      // Require answers
      if (!e.input.answers) throw new Error('No answer/answers')

      // Sorted
      solutions[index] = e.input.answers.sort()
    }

    // Filter null
    solutions = solutions.filter(e => e)
    return { quiz: quizData, solutions }
  }
}
