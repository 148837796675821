import React from 'react'
import snarkdown from 'snarkdown'
import ReactHtmlParser from 'react-html-parser'
import RBChatInput from '../components/RBChatInput'
// import RBCodeHightlight from '../components/RBCodeHighlight'
import RBCards from '../components/RBCards'
// import RBVideo from '../components/RBVideo'
import RBTables from '../components/RBTables'

import styled from 'styled-components'
import RBFillInput from '../components/RBFillInput'
import RBFullInput from '../components/RBFullInput'

const Imagez = styled.img`
   {
    display: flex;
    width: 320px;
    height: auto;

    border-radius: 1em;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.2em;
  }
`

const buildMessage = msgs =>
  msgs && msgs.map((msg, index) => <p key={index}>{typeof msg === 'string' ? ReactHtmlParser(snarkdown(msg)) : msg}</p>)
const buildImages = imgs => imgs && imgs.map((src, index) => <Imagez key={index} src={src} />)

const Holderz = styled.div`
   {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }
`

export default ({
  uid,
  msgs,
  imgs,
  img,
  replies,
  input,
  active,
  code,
  minigame,
  buttonRef,
  extraButtons,
  cards,
  video,
  tables,
  fill,
  full
}) => {
  // TODO : refactor this ;(
  const onChange = value => (replies[1].onClick = () => window.open(value, '_blank'))

  return uid !== '0' ? (
    <Holderz>
      <dd className='to'>{buildMessage(msgs)}</dd>
    </Holderz>
  ) : (
    <Holderz>
      <dd className='from'>
        <img alt='you' className='you' src={img} />
        {buildImages(imgs)}
        {buildMessage(msgs)}
      </dd>
      {cards && <RBCards cards={cards} onChange={onChange} />}
      {/* {video && <RBVideo src={video.src} />} */}
      {tables && <RBTables tables={tables} />}
      {fill && <RBFillInput fill={fill} active={active} />}
      {full && <RBFullInput full={full} active={active} />}
      {/* {minigame} */}
      {/* {code && <RBCodeHightlight language={code.language}>{code.text}</RBCodeHightlight>} */}
      <RBChatInput replies={replies} input={input} active={active} buttonRef={buttonRef} extraButtons={extraButtons} />
    </Holderz>
  )
}
