import SVG2JSX from '../lib/SVG2JSX'

const Stage = ({ clientInfo, draw }) => {
  // Init
  const height = 380

  // Canvas
  const svg2jsx = new SVG2JSX('320', `${height}`)

  // Border
  svg2jsx.rect({ x: 0, y: 0, width: 320, height, fill: '#F0F0F0' })

  // Draw
  draw && draw(svg2jsx)

  return svg2jsx.jsx()
}

export default Stage
