import React from 'react'
import RBConsoleHeader from './RBConsoleHeader'

import styled from 'styled-components'

const MAX_CHAR = 48
const SIZE = 9.5

export const Containerz = styled.div`
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;

  .selection {
    display: inline-block;
    border-radius: 0.3em;
    border: none;
  }

  br + .selection {
    margin-top: 2px;
  }
`

export const Answerz = styled.div`
  margin: auto;
  display: flex;
  flex-flow: row warp;
  justify-content: flex-end;
  flex-wrap: wrap
  margin-top: 0.25em;
`

const font = `
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
`

export const Codez = styled.div`
  ${font}
  font-size: 1em;

  border-radius: 0.6em;
  padding: 0.5em;
  background-color: rgb(21, 23, 24);
  color: white;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 0.25em;
  width: fit-content;

  transition: width 0.5s ease;

  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 16px 32px 0px;

  pre {
    ${font}
    display: initial;
  }

  .statement {
    display: initial;
    color: #cc0066;
  }

  .quote {
    color: #00cc66;
  }

  .comment {
    color: olive;
  }

  .cursor {
    color: olive;
    animation: blink 1s infinite;

    @keyframes blink {
      0% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  }
`

const barberpole = `
background-image: repeating-linear-gradient(-45deg, transparent, transparent 0.6rem, pink 0.6rem, pink 1.4rem);
background-size: 200% 200%;
animation: barberpole 1s linear infinite;

@keyframes barberpole {
  100% {
    background-position: 100% 100%;
  }
}
`

export const Selectionz = styled.span`
  ${props => (props.isAnimate ? barberpole : '')}
  background-color: ${props => (props.isFilled ? 'rgb(21, 23, 24);' : '#cc0088')};
`

export const Inputz = styled.input`
  ${font}
  font-size: 1em;
  -webkit-appearance: none;

  background-color: rgb(21, 23, 24);
  width: ${props => props.width};

  border: none;
  border-radius: 0.3em;

  height: 1.2em;
  color: ${props => props.color || '#cc0066'};
  -webkit-text-fill-color: ${props => props.color || '#cc0066'};

  :disabled {
    opacity: 1;
  }

  margin: 1px;
  padding: 1px;
  display: block;
`
const quoteCount = word => (word.match(/"/g) || []).length

const quote = (word, suffix) => {
  if (word && quoteCount(word) === 2) {
    const quotes = []

    word.split('"').forEach(
      (e, i) =>
        e.length > 0 &&
        quotes.push(
          i === 1 ? (
            <span key={i} className="quote">
              "{e}"
            </span>
          ) : (
            <span key={i}>{e}</span>
          )
        )
    )
    quotes.push(<span key="suffix">{suffix}</span>)
    return quotes
  } else return word + suffix
}

const highlight = (i, word, banks, isWarp = false, suffix = '') => {
  if (word && word.indexOf('(') > -1) {
    return (
      <span key={i}>
        {highlight(i, word.split('(')[0], banks, isWarp)}({highlight(i, word.split('(')[1], banks, isWarp)}
        {suffix}
      </span>
    )
  }

  if (word && word.indexOf(':') > -1) {
    return (
      <span key={i}>
        {highlight(i, word.split(':')[0], banks, isWarp)}:{highlight(i, word.split(':')[1], banks, isWarp)}
        {suffix}
      </span>
    )
  }

  return banks.indexOf(word.trim()) > -1 ? (
    <pre className="statement" key={i}>
      {isWarp && i > 0 ? <br /> : null}
      {quote(word, suffix)}
    </pre>
  ) : (
    <pre key={Math.random()}>{quote(word, suffix)}</pre>
  )
}

const splitWithQuote = word => {
  const words = word.split(' ')
  let results = []
  let i = 0
  while (i < words.length) {
    if (quoteCount(words[i]) === 1 && words[i + 1] && quoteCount(words[i + 1]) === 1) {
      results.push(words[i] + ' ' + words[++i])
    } else results.push(words[i])
    i++
  }

  return results
}

export const FilledInput = ({ language, title = '', text, texts, answers, banks, cursor = false }) => {
  let j = 0

  // Python
  if (language === 'python') {
    if (!texts) texts = [text]

    return (
      <Codez>
        <RBConsoleHeader title={title} />
        {texts.map((text, i) => {
          const inputs = text.split('____')
          let isWarp = text.length > MAX_CHAR
          return (
            <span key={i}>
              {inputs.map((e, i) => {
                isWarp = isWarp || banks.indexOf(e) > -1

                // No input fields
                if (inputs.length === 1) {
                  return splitWithQuote(inputs[0]).map((ee, ii) => highlight(ii, ee, banks, isWarp, ' '))
                }

                // No more input fields
                if (i === inputs.length - 1) return highlight(i, e, banks, isWarp, '')

                return [
                  e !== '' && highlight(i, e, banks, isWarp, ''),
                  highlight(`answer_${i}`, answers[j++], banks, isWarp, '')
                ]
              })}
              <br />
            </span>
          )
        })}
      </Codez>
    )
  }

  // SQL
  const _texts = text.split(' ')

  return (
    <Codez>
      <RBConsoleHeader title={title} />
      {text && text.indexOf('#') === 0 ? (
        <span className="comment">{text}</span>
      ) : (
        answers &&
        _texts.map((e, i) => {
          let isWarp = text.length > MAX_CHAR
          if (e === '____') {
            // Has previous as statement?
            if (i > 0 && j > 0 && _texts[i - 1] === '____') {
              isWarp = isWarp && banks.indexOf(answers[j - 1]) === -1
            }

            // Answered Input
            return highlight(i, answers[j++], banks, isWarp, ' ')
          } else if (e === '') {
            return <br key={`br${i}`} />
          } else {
            // Not input
            return highlight(i, e, banks, isWarp, ' ')
          }
        })
      )}
      {cursor && <span className="cursor">█</span>}
    </Codez>
  )
}

const parsePython = (indexRef, inputs, language, focusIndex, inputRefs, active, answers, banks) => {
  return inputs.map((e, i) => {
    const answer = answers ? answers[indexRef.j] : false

    const isFilled = answer
    const isAnswered = banks.indexOf(answer) > -1
    const isWarp = banks.indexOf(e) > -1

    // No input fields
    if (inputs.length === 1) {
      let results = []
      let foo = inputs[0].split('   ')
      results = foo.map((ee, ii) => {
        return [
          ee === '' && <pre key={ii}>{'    '}</pre>,
          splitWithQuote(ee).map((eee, iii) => highlight(iii, eee, banks, isWarp, eee === '' ? '' : ' '))
        ]
      })

      return results
    }

    // No more input fields
    if (i === inputs.length - 1) return highlight(i, e, banks, isWarp, '')

    return [
      e !== '' &&
        splitWithQuote(e).map((ee, ii) => highlight(ii, ee, banks, isWarp, ii < e.split(' ').length - 1 ? ' ' : '')),
      <Selectionz
        className="selection"
        key={`s${i}`}
        isAnimate={active && indexRef.j === focusIndex}
        isFilled={isFilled}
      >
        <Inputz
          ref={inputRefs[indexRef.j++]}
          isFilled={isFilled}
          width={isFilled ? `${SIZE * answer.length}px` : `3em`}
          color={isAnswered ? '#cc0066' : 'white'}
          disabled
        />
      </Selectionz>
    ]
  })
}

export const FillInput = ({
  language,
  title = '',
  text,
  texts,
  focusIndex,
  inputRefs,
  active,
  answers,
  corrects,
  banks
}) => {
  // Python
  if (language === 'python') {
    if (!texts) texts = [text]
    let indexRef = { j: 0 }
    return (
      <Codez>
        <RBConsoleHeader title={title} />
        {texts.map((text, i) => {
          const inputs = text.split('____')
          return (
            <span key={i}>
              {parsePython(indexRef, inputs, language, focusIndex, inputRefs, active, answers, banks)}
              <br />
            </span>
          )
        })}
      </Codez>
    )
  }

  // SQL
  let j = -1
  // const isWarp = true // text.length > MAX_CHAR
  const _texts = text.split(' ')

  return (
    <Codez>
      <RBConsoleHeader title={title} />
      {_texts.map((e, i) => {
        if (e === '____') {
          // Focus input -> Play animation
          j++

          const answer = answers ? answers[j] : false
          const correct = corrects[j]

          let isWarp = banks.indexOf(correct) > -1

          // Has previous as statement?
          if (_texts[i - 1] === '____' && corrects[j - 1]) {
            isWarp = isWarp && banks.indexOf(corrects[j - 1]) === -1
          }

          const isFilled = answer
          const isHighlight = banks.indexOf(answer) > -1

          return [
            i > 0 && isWarp && <br key={`br${i}`} />,
            <Selectionz className="selection" key={i} isAnimate={active && j === focusIndex} isFilled={isFilled}>
              <Inputz
                ref={inputRefs[j]}
                isFilled={isFilled}
                width={isFilled ? `${SIZE * answer.length}px` : `3em`}
                color={isHighlight ? '#cc0066' : 'white'}
                disabled
              />
            </Selectionz>,
            <span key={`space${i}`}>{` `}</span>
          ]
        } else if (e === '') {
          return <br key={`br${i}`} />
        } else {
          // Not input
          let isWarp = banks.indexOf(e) > -1
          return highlight(i, e, banks, isWarp, ' ')
        }
      })}
    </Codez>
  )
}
