import React, { useContext, useState, useEffect, useRef } from 'react'
import { trackButton } from '../lib/SpyUtil'
import RBOutput from './RBOutput'
import { Containerz, Answerz, FilledInput, FillInput } from './RBFillInputHelper'

import { Buttonz } from '../ui/Buttonz'
import _ from 'underscore'
import QuestContext from '../quests/QuestContext'

// TODO : optimize this
import sql_banks from '../datas/raw/sql/bigquery.json'
import python_banks from '../datas/raw/python/python.json'
const BANKS = {
  sql: sql_banks,
  python: python_banks
}

const RBFillInput = ({ fill, active }) => {
  const { index, code, outputs, type, corrects, incorrects = [], limit = 4 } = fill
  const banks = BANKS[type]

  const { dispatchUserAnswers, getUserAnswers } = useContext(QuestContext)
  let inputRefs = []
  for (let i = 0; i < limit; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    inputRefs.push(useRef())
  }

  const [focusIndex, setFocusIndex] = useState(0)
  const [fillTotal] = useState(fill.corrects.length)
  const [formIndex] = useState(fill.index)
  const [answers, setAnswers] = useState([])
  const [habits, setHabits] = useState([])

  // Can we do better?
  let samples = _.sample(banks, limit)
  samples = _.difference(samples, corrects)
  samples = _.sample(samples, limit - corrects.length - incorrects.length)
  samples = _.sample(samples.concat(corrects).concat(incorrects), limit)
  const [buttons] = useState(samples)
  const [buttonStates, setButtonStates] = useState(buttons.map(e => 'up'))

  const onClick = e => {
    // Spy
    const track = trackButton(e)
    setHabits(habits => [...habits, track])

    // Answer
    const i = parseInt(e.target.name)
    const answer = e.target.value

    if (buttonStates[i] === 'up') {
      inputRefs[focusIndex].current.value = answer

      setAnswers(answers => {
        answers[focusIndex] = answer
        return answers
      })
      setButtonStates(buttonStates => (buttonStates[i] = 'down') && buttonStates)

      let nextFocusIndex = -1
      inputRefs.forEach((e, i) => {
        if (nextFocusIndex === -1 && e.current && !e.current.value) nextFocusIndex = i
      })
      nextFocusIndex = nextFocusIndex === -1 ? focusIndex + 1 : nextFocusIndex
      setFocusIndex(nextFocusIndex)
    } else {
      let foundIndex = -1
      inputRefs.forEach((e, i) => {
        if (e.current && e.current.value === answer) foundIndex = i
      })

      inputRefs[foundIndex].current.value = null

      setAnswers(answers => {
        answers[foundIndex] = null
        return answers
      })
      setButtonStates(buttonStates => (buttonStates[i] = 'up') && buttonStates)
      setFocusIndex(foundIndex)
    }
  }

  useEffect(() => {
    // End of fills
    if (focusIndex === fillTotal) {
      dispatchUserAnswers({
        index: formIndex,
        type: 'COMMIT_ANSWERS',
        answers,
        habits
      })
    }
  }, [answers, focusIndex, dispatchUserAnswers, fillTotal, formIndex, habits])

  return (
    <Containerz className="no_left">
      {active ? (
        <FillInput
          active={active}
          language={code.language}
          text={code.text}
          texts={code.texts}
          focusIndex={focusIndex}
          inputRefs={inputRefs}
          answers={answers}
          corrects={corrects}
          banks={banks}
        />
      ) : (
        <FilledInput
          language={code.language}
          text={code.text}
          texts={code.texts}
          answers={getUserAnswers(index)}
          banks={banks}
        />
      )}
      {outputs && <RBOutput outputs={outputs} />}
      {active && (
        <Answerz>
          {buttons.map((e, i) => (
            <Buttonz
              name={i}
              key={i}
              type="button"
              backgroundColor={
                buttonStates[i] === 'up' ? 'radial-gradient(#ee0088, #cc0066)' : 'radial-gradient(#222222, #111111)'
              }
              color={buttonStates[i] === 'up' ? 'white' : '#cc0066'}
              borderColor={buttonStates[i] === 'up' ? '#ee3388' : '#333333'}
              boxShadowColor={buttonStates[i] === 'up' ? '#aa0033' : '#666666'}
              onClick={onClick}
              value={e}
              state={buttonStates[i]}
            >
              {e}
            </Buttonz>
          ))}
        </Answerz>
      )}
    </Containerz>
  )
}

export default RBFillInput
