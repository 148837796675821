import React, { useState, useEffect } from 'react'
import './App.css'
import styled from 'styled-components'

import QuestList from './quests/QuestList'

const Homez = styled.div`
   {
    background: white;
    text-align: center;
    display: flex;
    width: 100%;
    flex-flow: column;
    justify-content: center;
  }
`

const Sectionz = styled.div`
   {
    padding: 0px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: ${props => props.align};
  }
`

const Hrz = styled.hr`
   {
    width: 90%;
    border-width: 0.5px;
  }
`
const Footerz = styled.small`
  color: grey;

  span {
    color: coral;
  }
`

const Charz = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  small {
    color: coral;
  }
`

const Home = () => {
  const [quest, setQuest] = useState(null)

  useEffect(() => {
    if (!quest) return
    window.location.href = window.location.origin + '/quiz?id=' + quest
  }, [quest])

  return (
    <Homez>
      <Sectionz align="flex-end">
        <div>Hi! Wanna play? </div>
        <QuestList setQuest={setQuest} />
        <div>/</div>
        <Charz>
          <img alt="kat" src="/img/kat.png" width="32px" height="auto" />
          <img alt="duck" src="/img/duck.png" width="16px" height="auto" />
          <a href="/score" target="_blank">
            <small>
              <b>...Score?</b>
            </small>
          </a>
        </Charz>
      </Sectionz>
      <Hrz />
      <Sectionz align="flex-start">
        <Footerz>
          made w/ <span>❤</span> foxfox.io
        </Footerz>
      </Sectionz>
    </Homez>
  )
}

export default Home
