import React, { useEffect, useContext, useState, useReducer } from 'react'
import styled from 'styled-components'
import { AppContext } from './context'

import { decodeURLParams } from './lib/URLUtil'

import RBChatHeader from './components/RBChatHeader'
import RBChatContainer from './containers/RBChatContainer'
import QuestContext from './quests/QuestContext'

import Gimmick from './quests/Gimmick'
import QuestModel from './quests/QuestModel'

import { isLINEWebview, isDesktop, isIPhone } from './lib/DeviceUtil'
import _ from 'underscore'
import UserModel from './model/UserModel'

const Allz = styled.div`
   {
    width: 100%;
    max-width: 640px;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
`

const BodySectionz = styled.div`
   {
    padding: 0px;
    height: 100%;
    display: flex;
    flex-flow: column;
    background-color: ${props => props.backgroundColor || 'lightslategrey'};
  }
`
const HeaderSectionz = styled.div`
   {
    height: 40px;
    text-align: center;
    background: lightgray;
  }
`

const FooterSectionz = styled.div`
   {
    height: 2em;
    height: ${props =>
      isLINEWebview || isDesktop ? `2em` : `calc(var(--vh, ${isIPhone ? `0.25vh` : `0.18vh`}) * 100)`};
    background: lightgray;
  }
`

const Footerz = styled.small`
  span {
    color: #666666;
    text-shadow: -0.1em -0.1em darkgrey, 0.1em 0.1em white;
  }

  img {
    margin-bottom: -4px;
  }
`

const initialUserAnswers = []
const userAnswerReducer = (state, action) => {
  switch (action.type) {
    case 'COMMIT_ANSWERS':
      // console.log('userAnswerReducer:', action)
      QuestModel.commit(action.index, action.answers, action.habits)
      // TODO : Use reducer for commit?
      return state.concat(action.answers)
    default:
      throw new Error(action)
  }
}

const getBackgroundColor = questId => {
  const colors = { basic: 'darkseagreen', advance: 'lightcoral', pro: 'cadetblue', test: 'lightslategrey' }
  const questIds = questId.split('/')

  return colors[_.last(questIds)] || null
}

const Quest = props => {
  const { version } = useContext(AppContext)

  const section = props.match.params.section
  const questId = decodeURLParams(props.location.search).id

  // console.log('section:', section)
  // console.log('questId:', questId)
  UserModel.firebase && console.log('displayName:', UserModel.firebase.displayName)

  const [progress, setProgress] = useState(0)
  const [gimmick, setGimmick] = useState(null)
  const [userAnswers, dispatchUserAnswers] = useReducer(userAnswerReducer, initialUserAnswers)
  const [answerResults, setAnswerResults] = useState([])

  useEffect(() => {
    if (!userAnswers || userAnswers.length === 0) return
    const result = QuestModel.getLastResult()
    const lastResult = {
      index: QuestModel.getLastIndex(),
      result
    }
    // console.log('lastResult:', lastResult)

    setAnswerResults(answerResults => answerResults.concat([lastResult]))
  }, [userAnswers])

  if (!questId) {
    window.location.href = `${window.location.origin}/aww?from=quest&why=no-quest-id`
    return
  }

  // Wrong obsolete
  if (['basic', 'advance', 'pro', 'test'].indexOf(questId.split('/')[1]) === -1) {
    window.location.href = `${window.location.origin}`
    return
  }

  const onBeginEachTask = ({ index, action }) => {
    setProgress(index)
  }

  const getUserAnswers = index => {
    return QuestModel.getUserAnswers(index)
  }

  const questContext = { progress, setProgress, onBeginEachTask, setGimmick, dispatchUserAnswers, getUserAnswers }

  return (
    <Allz>
      <QuestContext.Provider value={questContext}>
        <Gimmick type={gimmick} />
        <HeaderSectionz>
          <RBChatHeader />
        </HeaderSectionz>
        <BodySectionz backgroundColor={getBackgroundColor(questId)}>
          <RBChatContainer section={section} questId={questId} answerResults={answerResults} />
        </BodySectionz>
      </QuestContext.Provider>
      <FooterSectionz>
        <Footerz>
          <img alt="FoxFox" src="/img/foxfox-logo.svg" width="16px" height="16px" /> <span>FoxFox v{version}</span>
        </Footerz>
      </FooterSectionz>
    </Allz>
  )
}

export default Quest
