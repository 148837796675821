class GameEngine {
  _frameId = null

  constructor (beforeRender, afterRender) {
    this.beforeRender = beforeRender
    this.afterRender = afterRender
  }

  render = () => (this._frameId = window.requestAnimationFrame(this.run))

  start = () => this._frameId || this.run()

  run = () => {
    this.beforeRender && this.beforeRender()

    this.render()

    this.afterRender && this.afterRender()
  }

  stop = () => window.cancelAnimationFrame(this._frameId)
}

export default GameEngine
