import { applyProfile } from '../containers/RBChatFormatter'

import QuestModel from '../quests/QuestModel'

import QuizFactory from '../factory/QuizFactory'
import GameFactory from '../factory/GameFactory'

export default class QuestRouter {
  static async route(json, quest) {
    let _nextId

    switch (quest.type.toUpperCase()) {
      case 'QUIZ':
        // Build quiz
        const { nextId, solutions, meta } = await QuizFactory.build(
          json,
          `${window.location.origin}/quiz/${quest.source}`
        )

        applyProfile(json)

        // Reset quest
        QuestModel.init()

        // Set current quest subject
        QuestModel.setQuestMetaData(meta)

        // Add solutions
        QuestModel.setHints(solutions)
        _nextId = nextId

        break
      case 'INTERVIEW':
        break
      case 'GAME':
        // Callback
        const onCommit = ({ index, datas }) => {
          QuestModel.commit(index, datas)
          // goto('GAME.END')
        }

        // Build game
        const { game_nextId, game_solutions } = await GameFactory.build(
          json,
          `${window.location.origin}/game/${quest.source}`,
          onCommit
        )

        applyProfile(json)

        // Reset subject and reset old state
        QuestModel.init()
        QuestModel.setQuestMetaData({
          type: 'game',
          questId: quest.id,
          title: 'todo'
        })

        // Add solutions
        QuestModel.setHints(game_solutions)
        _nextId = game_nextId

        break
      default:
        // Do something?
        break
    }

    return _nextId
  }
}
