import React from 'react'
import styled from 'styled-components'

const Sliderz = styled.div`
   {
    overflow-x: auto;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
`

const Containerz = styled.div`
   {
    margin-left: auto;
    margin-right: 0;

    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0.6em;

    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
      border-radius: 0.6em;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: papayawhip;
      font-size: small;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      font-weight: 400;
      color: sienna;
    }

    span {
      color: papayawhip;
      font-size: x-small;
      font-weight: bold;
      background-color: lightcoral;
    }
  }
`

const Imgz = styled.div`
   {
    width: 100%;
    height: 128px;
    border-radius: 0.6em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: url(${props => props.backgroundImage || ''});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #cccccc;
  }
`

const _cached = {}
const RBCards = ({ cards, onChange }) => {
  const { title, image, url } = cards[0]

  // TODO : set when select card
  onChange(url)

  // Cached
  if (_cached[url]) return _cached[url]

  return (_cached[url] = (
    <Sliderz>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        <Containerz>
          <Imgz backgroundImage={image[0].url} />
          <span role='img' aria-label='FoxFox'>
            ⭐⭐⭐⭐⭐ 12,631 reviews
          </span>
          <div className='title'>{title}</div>
        </Containerz>
      </a>
    </Sliderz>
  ))
}

export default RBCards
