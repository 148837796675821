import React from 'react'
import { keyToID } from '../lib/ObjectUtil'
import gameDAG from '../datas/game.json'
import { getJSON } from '@rabbotio/fetcher'
import PrecisionRecall from '../games/ml/precision-recall'

const parser = (gameData, onCommit) => {
  return {
    games: {
      'GAME#0': {
        msgs: ['TODO'],
        minigame: <PrecisionRecall onCommit={onCommit} />
      }
    },
    solutions: ['0_1']
  }
}

export default class GameFactory {
  static async build(json, gameURL = './game', onCommit) {
    // Game source
    if (!gameURL || gameURL === '') return

    // Load game
    const gameData = await getJSON(gameURL)
    if (!gameData) {
      throw new Error(`Not found : ${gameURL}`)
    }

    // Build game, solutions
    const { games, solutions } = parser(gameData, onCommit)

    // Mutate DAG
    Object.assign(json, gameDAG, games)

    // Apply id
    keyToID(json)

    return { nextId: 'GAME.BEGIN', solutions }
  }
}
