import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import { isDesktop, isIPhone } from '../lib/DeviceUtil'

const Containerz = styled.div`
  margin: auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`

const TableContainerz = styled.div`
  margin-left: ${isDesktop || isIPhone ? `0.5em` : `0.3em`};
  margin-right: ${isDesktop || isIPhone ? `0.5em` : `0.3em`};
  padding-top: ${isDesktop || isIPhone ? `0.5em` : `0.3em`};

  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0.6em;

  max-width: 90%;
  width: max-content;
  height: 100%;

  font-size: 0.95em;
`

const Headerz = styled.div`
  border-radius: 0.6em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: papayawhip;
  padding: ${isDesktop || isIPhone ? `0.5em` : `0.3em`};
  font-weight: bold;
  color: sienna;
`

const Framez = styled.div`
  border-radius: 0.6em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  background-color: white;

  table {
    margin: auto;
    border-spacing: 1px;
    padding: ${isDesktop ? `0.3em 0.5em 0.5em 0.5em` : `0.2em 0.3em 0.3em 0.3em`};

    .tr_0 {
      font-weight: 400;
      color: lightsalmon;
      height: 1.5em;
      vertical-align: top;
    }

    .tr_header {
      height: 1px;
      background-color: papayawhip;
    }

    td {
      padding-left: ${isDesktop || isIPhone ? `0.2em` : `0.1em`};
      padding-right: ${isDesktop || isIPhone ? `0.2em` : `0.1em`};

      small {
        color: white;
        border-radius: 0.4em;
        background-color: coral;
        padding: 0.2em;
        font-weight: bold;
      }
    }
  }
`

const Itemsz = ({ items, fields }) => {
  const _fields = fields || Object.keys(items[0])
  return (
    <table>
      <tbody>
        <tr key={0} className="tr_0">
          {_fields.map((f, j) => (
            <td key={j}>{f}</td>
          ))}
        </tr>
        {items.map((item, i) => (
          <tr key={i + 1} className={`tr_${i + 1}`}>
            {Object.values(item).map((f, j) => {
              if (!_fields.includes(Object.keys(item)[j])) return null
              return (
                <td key={j}>
                  {f}
                  {!f && <small>null</small>}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const RBTables = ({ tables }) => {
  return (
    <Containerz className="no_left">
      {tables.map(({ name, items, extra, fields }, i) => {
        if (extra) {
          return <div key={i}>{ReactHtmlParser(extra)}</div>
        }

        return (
          <TableContainerz key={i}>
            <Headerz>{name}</Headerz>
            <Framez>
              <Itemsz items={items} fields={fields} />
            </Framez>
          </TableContainerz>
        )
      })}
    </Containerz>
  )
}

export default RBTables
