function injectButtonEvent (replies, { onClick }) {
  // Guard
  if (!replies) return

  // Inject
  replies.map(
    element =>
      (element.onClick = event => {
        // Callback
        const { label, text, value, jump } = element
        onClick(event, { label, text, value, jump })
      })
  )
}

const parseValue = value => {
  const a = value.slice(0, value.indexOf(','))
  const b = value.slice(value.indexOf(',') + 1, value.length)
  return { a, b }
}

function injectSubmitEvent (input, { onSubmit }) {
  // Guard
  if (!input) return

  // Inject
  input.onSubmit = event => {
    // No refresh
    event.preventDefault()

    // Collect value
    const datas = []
    let { a, b } = {}
    const values = Array.from(event.target.elements)
      .filter(e => e.checked)
      .map(e => ({ a, b } = parseValue(e.value)) && datas.push(a) && b)

    // Prevent nothing select
    if (values.length === 0) return

    // Callback
    const { index, jump } = input
    onSubmit(event, { index, jump, values, datas })
  }
}

export { injectButtonEvent, injectSubmitEvent }
