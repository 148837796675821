let line = {
  displayName: null,
  userId: null,
  pictureUrl: null,
  statusMessage: null
}

let firebase = {
  uid: null,
  displayName: null,
  photoUrl: null
}

const UserModel = {
  line,
  firebase
}

export default UserModel