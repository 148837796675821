import React, { useContext, useState, useEffect } from 'react'

import { trackButton } from '../lib/SpyUtil'
// import { isLocal } from '../lib/DeveloperUtil'

import RBConsoleHeader from './RBConsoleHeader'
import RBOutput from './RBOutput'
import { Containerz, Answerz, Codez } from './RBFillInputHelper'

import { Buttonz } from '../ui/Buttonz'

import QuestContext from '../quests/QuestContext'

import sql_banks from '../datas/raw/sql/bigquery.json'

const INIT_TEXT = `# Click button below to answer`

const highlight = (i, word) => {
  return sql_banks.indexOf(word.trim()) > -1 ? (
    <pre className="statement" key={i}>
      {word}
    </pre>
  ) : (
    <pre key={i}>{word === '' ? '' : word}</pre>
  )
}

const parseHTML = text => {
  if (text === INIT_TEXT) {
    return (
      <span className="comment">
        {text}
        <br />
      </span>
    )
  } else {
    const texts = text.split('\n')
    return texts.map((e, i) => {
      const words = e.split(' ')
      return [
        words.map((word, j) => [highlight(j, word), j < words.length - 1 && <pre key={j + 1}>{` `}</pre>]),
        i < texts.length - 1 && <br key={i + 1} />
      ]
    })
  }
}

const GRADIENT_PURPLE = 'radial-gradient(darkorange, tomato)'
const BUTTON_BG_COLOR = { ' ': GRADIENT_PURPLE, '←': GRADIENT_PURPLE, '↵': GRADIENT_PURPLE }
const getButtonBackgroundColor = char => BUTTON_BG_COLOR[char] || 'radial-gradient(#ee0088, #cc0066)'

const BUTTON_BOX_SHADOW_COLOR = 'chocolate'
const getButtonBoxShadowColor = char => BUTTON_BOX_SHADOW_COLOR[char] || '#aa0033'

const FUNC_BUTTON_WIDTH = '6em'
const BUTTON_WIDTH = { ' ': FUNC_BUTTON_WIDTH, '←': FUNC_BUTTON_WIDTH, '↵': FUNC_BUTTON_WIDTH }
const getButtonWidth = char => BUTTON_WIDTH[char]

const BUTTON_TITLE = { ' ': ` SPACE ` }
const getButtonTitle = text => BUTTON_TITLE[text] || text

export const FullInput = ({ active, title, text }) => (
  <Codez>
    <RBConsoleHeader title={title} />
    {parseHTML(text)}
    {active && <pre className="cursor">█</pre>}
  </Codez>
)

// TODO
export const FilledFullInput = () => null

const onClick = (e, texts, setTexts, setHabits) => {
  // Spy
  const track = trackButton(e)
  setHabits(habits => [...habits, track])

  // Answer
  const answer = e.target.value

  // Command
  if (/^[A-Z]/.test(answer)) {
    let new_texts = []

    if (texts.length > 0 && ['SELECT', 'AS'].indexOf(answer) === -1) {
      if (answer === 'JOIN') {
        if (texts.length > 2 && ['LEFT', 'RIGHT', 'INNER', 'OUTER'].indexOf(texts[texts.length - 2]) > -1) {
          //do nothing
        } else {
          texts[texts.length - 1] !== '\n' && new_texts.unshift('\n')
        }
      } else {
        texts[texts.length - 1] !== '\n' && new_texts.unshift('\n')
      }
    }

    setTexts([...texts, ...new_texts, answer, ' '])
    return
  }

  switch (answer) {
    case '←':
      texts.pop()
      setTexts([...texts])
      return
    case '↵':
      setTexts([...texts, '\n'])
      return
    default:
      // Answer
      setTexts([...texts, answer])
      return
  }
}

const getTitle = text => `Remaining Character : ${text}`

const sha256 = async str => {
  const buf = await crypto.subtle.digest('SHA-256', new TextEncoder('utf-8').encode(str))
  return Array.prototype.map.call(new Uint8Array(buf), x => ('00' + x.toString(16)).slice(-2)).join('')
}

const RBFullInput = ({ full, active }) => {
  const { index = 0, outputs, code, validation, choices } = full

  const { dispatchUserAnswers } = useContext(QuestContext)

  const [buttons] = useState(choices)
  const [texts, setTexts] = useState([])
  const [text, setText] = useState('')
  const [title, setTitle] = useState(getTitle(validation.length))
  const [habits, setHabits] = useState([])

  useEffect(() => {
    const codeText = texts.join('')
    const remain = validation.length - codeText.length
    setTitle(getTitle(remain))
    setText(texts.length > 0 ? codeText : INIT_TEXT)

    // validate answer
    if (remain === 0) {
      const codeTextNoWarp = codeText.replace(/\n/g, ' ')

      sha256(codeTextNoWarp).then(hash => {
        dispatchUserAnswers({
          index,
          type: 'COMMIT_ANSWERS',
          answers: [hash],
          habits
        })
      })
    }
  }, [validation, texts, dispatchUserAnswers, index, habits])

  return (
    <Containerz className="no_left">
      <FullInput active={active} language={code.language} banks={sql_banks} title={title} text={text} />
      {outputs && <RBOutput outputs={outputs} />}
      {active && (
        <Answerz>
          {buttons.map((e, i) => (
            <Buttonz
              name={i}
              key={i}
              type="button"
              backgroundColor={getButtonBackgroundColor(e)}
              width={getButtonWidth(e)}
              color={'white'}
              borderColor={'#ee3388'}
              boxShadowColor={getButtonBoxShadowColor(e)}
              onClick={e => onClick(e, texts, setTexts, setHabits)}
              value={e}
            >
              {getButtonTitle(e)}
            </Buttonz>
          ))}
        </Answerz>
      )}
    </Containerz>
  )
}

export default RBFullInput
