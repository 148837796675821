export const keyToID = obj => {
  for (const key in obj) obj[key].id = key
}

export const csvToJson = csv => {
  const fields = csv[0].split(',')
  const _csv = csv.slice(1)

  return {
    fields,
    datas: _csv.map(e => {
      let item = {}
      e.split(',').forEach((f, j) => (item[`${fields[j]}`] = f))
      return item
    })
  }
}
