import React from 'react'
import QuestModel from '../quests/QuestModel'
import styled from 'styled-components'

const Containerz = styled.div`
   {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Barz = styled.div`
   {
    background-color: ${props => props.backgroundColor || 'cornsilk'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};

    border-radius: 0.6em;
    border-color: #f39c12;
    border-width: 1px;
    border-style: solid;
    margin-left: 6px;
  }
`

const ProgressBarz = styled.div`
   {
    background-color: ${props => props.backgroundColor || '#f39c12'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};

    border-radius: 0.6em;
    transition: width 0.5s ease;
  }
`

const Imgz = styled.img`
  padding-left: 6px;
  transform: scaleX(-1);
`

const Labelz = styled.p`
  width: fit-content;
  font-size: xx-small;
`

const RBChatHeader = () => {
  const questPercentText = `${QuestModel.questPercent}%`

  return (
    <Containerz>
      <Imgz alt='duck' src='/img/duck.png' width='16px' height='16px' />
      <Labelz>PROGRESS</Labelz>
      <Barz width='50%' height='8px'>
        <ProgressBarz width={questPercentText} />
      </Barz>
    </Containerz>
  )
}

export default RBChatHeader
