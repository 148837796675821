import React from 'react'
import { Buttonz } from '../ui/Buttonz'

import styled from 'styled-components'

const Questz = styled.div`
  display: flex;
  padding-top: 0.5em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const buildQuestButton = (quests, onClick) =>
  quests.map((e, i) => (
    <Buttonz key={i} value={e.src} onClick={onClick} height='auto'>
      {e.title}
    </Buttonz>
  ))

// TODO!: load from json
const quests = [
  {
    title: '🧪 Basic SQL',
    src: 'sql/basic'
  },
  {
    title: '🧪 Advance SQL',
    src: 'sql/advance'
  },
  {
    title: '🧪 Proficient SQL',
    src: 'sql/pro'
  }
]

const QuestComponent = ({ setQuest }) => {
  const onClick = e => setQuest(e.target.value)

  return <Questz>{buildQuestButton(quests, onClick)}</Questz>
}

export default QuestComponent
