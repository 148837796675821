import React from 'react'
import styled from 'styled-components'

const font = `font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;`
const Outputz = styled.span`
  div {
    ${font}
    width: fit-content;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 0.6em;

    background-color: oldlace;
    padding: 0.5em;
    margin-bottom: 0.5em;

    pre {
      ${font}
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const RBOutput = ({ outputs }) => (
  <Outputz>
    <span role="img" aria-label="output">
      👇🏻
    </span>
    <div>
      {outputs.map((e, i) => (
        <pre key={i}>{e}</pre>
      ))}
    </div>
  </Outputz>
)

export default RBOutput
