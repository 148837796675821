import React from 'react'
import { Buttonz } from '../ui/Buttonz'
// import { email } from './ui/Iconz'
/*
// Example
<Buttonz type='submit'>
  {email}
  <span>SUBSCRIBE</span>
</Buttonz>
*/

const isPositive = label => label.toLowerCase().indexOf('yes') > -1 || label.toLowerCase().indexOf('ใช่') > -1
const isNegative = label => label.toLowerCase().indexOf('no') > -1 || label.toLowerCase().indexOf('ไม่') > -1

const getBackgroudColor = label => {
  if (isPositive(label)) return '#2ecc71'
  if (isNegative(label)) return '#e74c3c'
}

const getBorderColor = label => {
  if (isPositive(label)) return '#27ae60'
  if (isNegative(label)) return '#c0392b'
}

function QuickReplyButton ({ label, value, onClick, disabled, theme }) {
  const { color, borderColor, backgroundColor } = theme
    ? {
      color: ['cornsilk', 'indianred'][theme],
      borderColor: ['mediumturquoise', 'peachpuff'][theme],
      backgroundColor: ['royalblue', 'linen'][theme]
    }
    : {
      color: 'cornsilk',
      borderColor: getBackgroudColor(label),
      backgroundColor: getBorderColor(label)
    }

  return (
    <Buttonz
      value={value}
      disabled={disabled}
      onClick={onClick}
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <span>{label}</span>
    </Buttonz>
  )
}

export default QuickReplyButton
