import * as firebase from 'firebase/app'
import 'firebase/auth'

import { getCustomTokenURI, setAccountInfoURI } from './Firebase'
import { postJSON } from '@rabbotio/fetcher'

const getCustomToken = async lineId => postJSON(getCustomTokenURI, { lineId })

const signInWithCustomToken = async token => firebase.auth().signInWithCustomToken(token)

const signInWithLINEId = async lineId => {
  // Get token
  const { data, error } = await getCustomToken(lineId)
  if (error) {
    console.error(error)
    throw error
  }

  // console.log('signInWithLINEId:', data.token)

  // Sign in with token
  return await signInWithCustomToken(data.token)
}

const updateUserProfile = async ({ displayName, photoUrl }) => {
  const user = firebase.auth().currentUser
  if (!user) throw new Error('User not logged in')

  const token = await user.getIdToken()
  const response = await postJSON(setAccountInfoURI, {
    idToken: token,
    displayName,
    photoUrl
  })

  return response
}

export { signInWithLINEId, updateUserProfile }
