import React from 'react'
import styled from 'styled-components'

const Containerz = styled.div`
   {
    width: fit-content;
    height: 20px;
    min-height: 20px;
    max-height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    > div,
    img {
      margin: 0.25em;
    }
  }
`
const Barz = styled.div`
   {
    background-color: ${props => props.backgroundColor || 'cornsilk'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};

    border-radius: 0.6em;
    border-color: #f39c12;
    border-width: 1px;
    border-style: solid;
  }
`

const ProgressBarz = styled.div`
   {
    background-color: ${props => props.backgroundColor || '#f39c12'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};

    border-radius: 0.6em;
    transition: width 0.5s ease;
  }
`

const Imgz = styled.span`
  background-color: lightgrey;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat
  border-radius: 50%;
  width: 16px;
  height: 16px;
`

const LabelLeftz = styled.div`
  width: 128px;
  text-align: right;
  font-size: xx-small;
  white-space: nowrap;
`

const LabelRightz = styled.div`
  width: 64px;
  text-align: left;
  font-size: xx-small;
  white-space: nowrap;
`

const prefixO = num => (`0` + num).substr(num.toString().length - 1, 2)
const toMorS = diff => {
  const minute = Math.floor(diff / 60000)
  const second = Math.round(((diff % 60000) - minute) / 1000)
  return `${prefixO(minute)}:${prefixO(second)}`
}

const ScoreItem = ({ displayName, photoUrl, percent, time, attempt }) => {
  return (
    <Containerz>
      <LabelLeftz>{displayName}</LabelLeftz>
      <Imgz alt="duck" src={photoUrl} />
      <Barz width="64px" height="8px">
        <ProgressBarz width={`${percent}%`} />
      </Barz>
      <LabelRightz>{`${percent.toFixed(2)}% ⏱️ ${toMorS(time)} 🧪${attempt}`}</LabelRightz>
    </Containerz>
  )
}

export default ScoreItem
