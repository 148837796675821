import { applyType, createResultFlow } from './FlowUtil'

export default class Python {
  static async parse (quizData) {
    const _quizData = { ...quizData }
    const meta = quizData.meta
    const raw = quizData.raw

    // Not use
    delete _quizData.meta
    delete _quizData.raw

    // Define type
    applyType(_quizData, 'python')

    // Generate next responder
    const solutions = createResultFlow(_quizData, raw.corrects, raw.incorrects)

    return { quiz: _quizData, solutions, meta, raw }
  }
}
