// Styles
import styled from 'styled-components'

export const Buttonz = styled.button`
  width: ${props => props.width || 'fit-content'};
  height: ${props => props.height || 'auto'};
  color: ${props => props.color || 'cornsilk'};
  border: solid 1px ${props => props.borderColor || 'cornflowerblue'};
  background: ${props => props.backgroundColor || 'royalblue'};
  box-shadow: 0px ${props => (props.state === 'down' ? '-2px' : '4px')} 0px ${props =>
  props.boxShadowColor || 'darkslategrey'};
  top: ${props => (props.state === 'down' ? '3px' : '0px')}

  padding: 0.3em;
  padding-left: ${props => props.paddingLeft || '0.3em'};
  padding-right: ${props => props.paddingRight || '0.3em'};

  border-top-weight: 0;
  font-weight: 500;
  font-size: 1em;
  
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  
  position: relative;
  min-width: 2em;
  min-height: 2em;
  display: inline-block;
  text-decoration: none;
  border-radius: 6px;
  text-align: center;
  margin: 0.2em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  outline: none;
  transition: all 250ms ease;

  :active {
    box-shadow: none !important;
    position: relative;
    top: 2px;
    transition: all 250ms ease;
  }

  :disabled {
    color: #bdc3c7;
    background: #ecf0f1;
    cursor: auto;
    box-shadow: none !important;
  }

  svg {
    display: inline-block;
    margin-top: 0.1em;
    padding: 0.2em;
  }

  span {
    line-height: 0.3em;
  }
`
