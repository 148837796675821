import React from 'react'
import styled from 'styled-components'

import GimmickFirework from './GimmickFirework'

const Gimmickz = styled.div`
  overflow: hidden;
`

const Gimmick = ({ type, delay }) => {
  return type ? (
    <Gimmickz>
      <GimmickFirework delay={delay} />
    </Gimmickz>
  ) : null
}

export default Gimmick
