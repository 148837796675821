import React from 'react'
import { Buttonz } from '../ui/Buttonz'

import styled from 'styled-components'

const Scorez = styled.div`
  display: flex;
  padding-top: 0.5em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const buildScoreButton = (quests, onClick) =>
  quests.map((e, i) => (
    <Buttonz key={i} value={e.src} onClick={onClick} height="auto">
      {e.title}
    </Buttonz>
  ))

// TODO!: load from json
const quests = [
  {
    title: '🧪 Basic SQL',
    src: 'quiz-sql-basic'
  },
  {
    title: '🧪 Advance SQL',
    src: 'quiz-sql-advance'
  },
  {
    title: '🧪 Proficient SQL',
    src: 'quiz-sql-pro'
  }
]

const ScoreComponent = ({ setQuest }) => {
  const onClick = e => setQuest(e.target.value)

  return <Scorez>{buildScoreButton(quests, onClick)}</Scorez>
}

export default ScoreComponent
