import React from 'react'
import Stage from '../Stage'
import GameEngine from '../GameEngine'

class PrecisionRecall extends React.Component {
  draw = svg => {
    let y = 0

    // Button
    const onClick = event => {
      // From some element or input value
      const answer = event.target.value || event.target.id
      this.props.onCommit({ index: 0, datas: [answer] })
    }

    svg.text({
      x: 320 / 2,
      y: (y = y + 32),
      id: '0_0',
      fontSize: 32,
      text: '💩',
      onClick
    })

    svg.text({
      x: 320 / 2,
      y: (y = y + 32),
      id: '0_1',
      fontSize: 32,
      text: '🍙',
      onClick
    })
  }

  render () {
    return <Stage clientInfo={this.clientInfo} draw={this.draw} />
  }

  componentDidMount = async () => {
    // SVG
    this.svg = document.getElementById('svg')

    // Game
    this.gameEngine = new GameEngine(this.beforeRender)
    this.gameEngine.start()
  }

  componentWillUnmount = () => this.gameEngine.stop()

  beforeRender = () => {}
}

export default PrecisionRecall
