import React, { useState, useEffect } from 'react'
import './App.css'
import styled from 'styled-components'

import ScoreModel from './scores/ScoreModel'
import ScoreItem from './scores/ScoreItem'
import ScoreList from './scores/ScoreList'

import { decodeURLParams } from './lib/URLUtil'

const Scorez = styled.div`
   {
    background: white;
    text-align: center;
    display: flex;
    width: 100%;
    flex-flow: column;
    justify-content: center;
  }
`

const Sectionz = styled.div`
   {
    padding: 0px;
    height: ${props => props.height || '100%'};
    display: flex;
    flex-flow: column;
    justify-content: ${props => props.align};
  }
`

const Hrz = styled.hr`
   {
    width: 90%;
    border-width: 0.5px;
  }
`
const Footerz = styled.small`
  color: grey;

  span {
    color: coral;
  }
`
const ScoreContainerz = styled.div`
  max-height: 80vh;
  weight: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const ScoreBoard = ({ scores }) => {
  if (!scores) return <small>Please wait...</small>

  if (scores.length <= 0) {
    return 'Sorry! No score yet.'
  }

  return (
    <ScoreContainerz>
      {scores.map((e, i) => (
        <ScoreItem key={i} {...e} />
      ))}
    </ScoreContainerz>
  )
}

const Score = props => {
  let questId = decodeURLParams(props.location.search).id

  const [scores, setScores] = useState(null)
  const [quest, setQuest] = useState(null)

  useEffect(() => {
    if (!quest) return
    window.location.href = window.location.origin + '/score?id=' + quest
  }, [quest])

  useEffect(() => {
    if (!scores) {
      ;(async () => {
        const newScores = await ScoreModel.getHighScore(questId)
        // console.log(JSON.stringify(newScores))
        setScores(newScores)
      })()
    } else {
      // TODO : interval
    }
  }, [scores, questId])

  return (
    <Scorez>
      <Sectionz align="flex-end">
        {questId ? <ScoreBoard scores={scores} /> : <ScoreList setQuest={setQuest} />}
        <div>/</div>
        <div>
          <img alt="kat" src="/img/kat.png" width="32px" height="auto" />
          <img alt="duck" src="/img/duck.png" width="16px" height="auto" />
        </div>
      </Sectionz>
      <Hrz />
      <Sectionz align="flex-start" height="25%">
        <Footerz>
          made w/ <span>❤</span> foxfox.io
        </Footerz>
      </Sectionz>
    </Scorez>
  )
}

export default Score
