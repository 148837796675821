import { db, increment } from '../services/Firebase'
import { isLocal } from '../lib/DeveloperUtil'

const PREFIX = `prod-`

export default class ScoreModel {
  static getHighScore = async questId => {
    const docRef = db
      .collection(`rank-${questId}`)
      .orderBy('percent', 'desc')
      .orderBy('time', 'asc')
      .limit(20)

    const { docs } = await docRef.get()
    let lists = docs.map(doc => doc.data())

    // Retries
    // lists.forEach(e => (e.attempt = e.attempts? e.attempts.length : 1))

    // Percent & Time
    lists = lists.sort((a, b) => (a.percent === b.percent ? a.time - b.time : 1))

    /*
    // DISTINCT
    let _list = []
    const uids = []
    const attemptData = {}
    lists.forEach(e =>
      uids.includes(e.uid) ? (attemptData[e.uid] = (attemptData[e.uid] || 1) + 1) : uids.push(e.uid) && _list.push(e)
    )

    // Retries
    _list.forEach(e => (e.attempt = attemptData[e.uid] || 1))

    // Percent & Time
    _list = _list.sort((a, b) => (a.percent === b.percent ? a.time - b.time : 1))

    return _list
    */

    return lists
  }

  static addScore = async (questId, score) => {
    // Init
    const { uid, displayName, photoUrl, percent, time } = score
    const highScoreData = { displayName, photoUrl, percent, time, attempt: increment }
    score.habits = score.habits.map(e => e.join('\n'))

    // No use
    // TODO : finalize schema
    delete score.title
    delete score.level
    delete score.type
    delete score.total

    // Test
    if (isLocal) {
      console.log('// NOT SAVE (LOCAL)')
      console.log(`questId:`, questId)
      console.log(`score:`, score)
      console.log(`db:`, `${PREFIX}${questId}`)
      console.log('----------------//')
      return
    }

    const batch = db.batch()

    // Add high score
    batch.set(db.collection(`rank-${questId}`).doc(uid), highScoreData, { merge: true })

    // Add score
    batch.set(db.collection(`${PREFIX}${questId}`).doc(), score)

    return batch.commit()
  }
}
