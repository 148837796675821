import _ from 'underscore'
import { nSQL } from '@nano-sql/core'
import { applyType, createResultFlow } from './FlowUtil'

const queryWithUnderscore = (datas, rawItems) => {
  if (!rawItems._) throw new Error('Expected _')
  const args = rawItems['_']
  const func = args[0]

  // Secure eval
  if (args[2] && typeof args[2] === 'string' && args[2].indexOf('(') > -1) throw new Error('Not allowed')
  // eslint-disable-next-line
  const context = eval(args[2])
  const items = _[func](datas, context)
  return items
}

const query = async (datas, items) => {
  // Use multiple underscore
  if (Array.isArray(items)) {
    // Raw?
    if (!items[0]._) return items

    // Hack for 3 depths
    let _items = queryWithUnderscore(datas, items[0])
    _items = items[1] ? queryWithUnderscore(_items, items[1]) : _items
    return items[2] ? queryWithUnderscore(_items, items[2]) : _items
  }

  // Use underscore?
  if (items._) return queryWithUnderscore(datas, items)

  // Use nano
  if (!items.query) throw new Error('Expected query')

  const flow = nSQL(datas).query(items.query[0], items.query[1])

  items.limit && flow.limit(items.limit)
  items.distinct && flow.distinct(items.distinct)
  items.join && flow.join(items.join)
  items.where && flow.where(items.where)
  items.having && flow.having(items.having)

  items.groupBy && flow.groupBy(items.groupBy)
  items.orderBy && flow.orderBy(items.orderBy)

  items.union && flow.union(items.union)

  return flow.exec()
}

export default class SQL {
  static async parse (quizData) {
    const _quizData = { ...quizData }
    const meta = quizData.meta
    const raw = quizData.raw

    // Not use
    delete _quizData.meta
    delete _quizData.raw

    // Define type
    applyType(_quizData, 'sql')

    // Create database and table
    const { tables } = quizData.raw

    // Add tables
    let promises
    for (let key in _quizData) {
      let e = _quizData[key]

      promises = e.tables.map((table, i) => {
        if (table.extra) return null
        if (!tables) return null

        // Table data
        const tableRaw = tables[_.findIndex(tables, { name: table.name })] || tables[0]

        // Eval items func to data
        // const fields = Object.keys(tableRaw.model).map(e => e.split(':')[0])
        // table.fields = table.fields || fields

        // Query from source or first table
        if (i < e.tables.length - 1) {
          // Use multiple underscore
          if (Array.isArray(table.items)) {
            // Hack for 3 depths
            // Use underscore for faster sync
            const items = table.items
            let _items = queryWithUnderscore(tableRaw.datas, items[0])
            _items = items[1] ? queryWithUnderscore(_items, items[1]) : _items
            table.items = items[2] ? queryWithUnderscore(_items, items[2]) : _items

            return null
          }

          // Use underscore for faster sync
          table.items = queryWithUnderscore(tableRaw.datas, table.items)
          return null
        } else {
          // Use SQL to match real query answer
          return query(e.tables[0].items, table.items).then(items => (table.items = items))
        }
      })
    }

    // This could be slow?
    promises.reduce((p, promise) => p.then(promise), Promise.resolve())

    // Generate next responder
    const solutions = createResultFlow(_quizData, raw.corrects, raw.incorrects)

    return { quiz: _quizData, solutions, meta, raw }
  }
}
