import React, { useState } from 'react'
import QuickReplyButtonList from '../components/QuickReplyButtonList'
import { Formz } from '../ui/Formz'
import { Buttonz } from '../ui/Buttonz'

import snarkdown from 'snarkdown'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
const CheckboxGroupz = styled.div`
   {
    transform: translateZ(${props => props.refresh || 0});
    display: flex;
    width: auto;
    text-align: left;
    flex-flow: row wrap;
    flex-direction: column;
    border-radius: 0.6em;
    background: #fefefe;
    align-content: space-around;
    padding: 0.5em;

    border: lemonchiffon;
    border-style: solid;
    border-width: 1px;
    box-shadow: -0.1em -0.1em lightpink, 0.1em 0.1em lightskyblue;

    input[type='checkbox'],
    input[type='radio'] {
      display: inline-block;
      transform: scale(1.3);
      margin-right: 0.8em;
    }

    input[type='checkbox'] {
      border-radius: 2px;
    }

    label {
      padding: 0.1em;
      cursor: pointer;
      padding-left: 1.6em;
      text-indent: -1.6em;
    }
  }
`

const _build = (type, index, list, onSubmit, buttonRef, refresh, setRefresh, extraButtons) => {
  let checked = 0
  const onChange = e => {
    e.target.checked ? checked++ : checked--
    buttonRef.current.disabled = checked === 0

    // Prevent repaint bug ;(
    setRefresh(Math.random() / 100)
  }

  const ExtraButtonz = () =>
    extraButtons
      ? extraButtons.map((e, i) => (
        <Buttonz
          key={i}
          type='button'
          backgroundColor='linen'
          color='indianred'
          borderColor='peachpuff'
          onClick={e.onClick}
        >
          <span role='img' aria-label={e.label}>
            {e.label}
          </span>
        </Buttonz>
      ))
      : null

  const name = type === 'radio' ? `radio-${+new Date()}` : undefined

  const _parse = (list, type) =>
    list.map((e, i) => (
      <label key={i}>
        <input type={type} defaultChecked={false} name={name} value={`${i},${e}`} onChange={onChange} />
        {ReactHtmlParser(snarkdown(e))}
      </label>
    ))

  return (
    <Formz onSubmit={onSubmit}>
      <CheckboxGroupz refresh={refresh}>{_parse(list, type)}</CheckboxGroupz>
      <ExtraButtonz />
      <Buttonz type='submit' ref={buttonRef} disabled>
        <span role='img' aria-label='Submit'>
          💡ตอบ
        </span>
      </Buttonz>
    </Formz>
  )
}

function RBChatInput ({ replies, input, active, buttonRef }) {
  const [refresh, setRefresh] = useState(null)

  if (!active) return null
  if (replies && replies[0] && !replies.disabled) {
    return <QuickReplyButtonList data={replies} />
  }

  if (input && !input.disabled) {
    const { index, checkboxes, radios, onSubmit, extraButtons } = input

    if (checkboxes) {
      return _build('checkbox', index, checkboxes, onSubmit, buttonRef, refresh, setRefresh, extraButtons)
    } else if (radios) {
      return _build('radio', index, radios, onSubmit, buttonRef, refresh, setRefresh, extraButtons)
    }
  }

  return null
}

export default RBChatInput
