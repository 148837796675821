import profiles from '../datas/profiles.json'
import { keyToID } from '../lib/ObjectUtil'

const getProfile = () => profiles['1']

// Merge chat & profiles
const prepDAG = dag => {
  // Apply profile
  const json = Object.assign({}, dag)
  keyToID(json)
  return applyProfile(json)
}

const applyProfile = source => {
  for (let key in source) {
    const element = source[key]
    element.uid = element.uid || '0'
    const profile = profiles[element.uid]
    source[key] = Object.assign({}, element, profile)
  }

  return source
}

export { getProfile, applyProfile, prepDAG }
