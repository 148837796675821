import React from 'react'
import styled from 'styled-components'

const _RBConsoleHeader = ({ className, title }) => (
  <div className={className}>
    <div>
      <span />
      <span />
      <span />
    </div>
    <div>{title}</div>
  </div>
)

const RBConsoleHeader = styled(_RBConsoleHeader)`
  line-height: 1em;
  padding-left: 0.25em;
  padding-bottom: 0.5em;
  display: flex;
  width: 100%;
  font-size: small;
  color: darkgrey;

  div {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;

    span {
      background-color: #ff5f56;
      box-shadow: 0 0 1px #e0443e;

      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 6px;
    }

    span + span {
      background-color: #ffbd2e;
      box-shadow: 0 0 1px #dea123;
    }

    span + span + span {
      background-color: #27c93f;
      box-shadow: 0 0 1px #1aab29;
    }
  }

  div + div {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
      width: 100%;
    }
  }
`
export default RBConsoleHeader
