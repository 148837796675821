import React, { useState, useContext, useEffect, useRef } from 'react'
import Msgz from '../ui/Msgz'
import styled from 'styled-components'
import { getChatStyle, getTypingChatData } from './RBChatStyles'

import { addController } from './RBChatController'
import { prepDAG } from './RBChatFormatter'

import questDAG from '../datas/quest.json'

import UserModel from '../model/UserModel'

import { questResponder } from '../quests/QuestResponder'
import QuestModel from '../quests/QuestModel'
import QuestContext from '../quests/QuestContext'
import QuestRouter from '../quests/QuestRouter'

import { scrollToBottom } from '../lib/ScrollUtil'
import { isLocal } from '../lib/DeveloperUtil'
import _ from 'underscore'

const Containerz = styled.div`
   {
    flex-flow: row;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    ::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`
const Blankz = styled.div`
   {
    width: 100%;
    height: 2em;
    display: block;
  }
`

const Chatz = getChatStyle('#3498db', '#ecf0f1')
const json = prepDAG(questDAG)
// console.log(json)

function RBChatContainer({ section, questId, answerResults }) {
  const { onBeginEachTask, setGimmick } = useContext(QuestContext)

  // Apply questId
  json['BEGIN'][section] = `${questId}.json?v=2`

  QuestModel.userId = UserModel.firebase.uid

  const [quest, setQuest] = useState(null)
  const [chatId, setChatId] = useState('ONBOARD')
  const [nextId, setNextId] = useState(null)
  const [typeAndJumpId, setTypeAndJumpId] = useState(null)
  const [chatDatas, setChatDatas] = useState([json[chatId]])
  const [usedChatDataId, setUsedChatDataId] = useState(null)

  const [answerPath, setAnswerPath] = useState(null)

  const chatRef = useRef(null)
  const buttonRef = useRef(null)

  // Replay
  const onRetry = () => {
    setAnswerPath(null)
  }

  // Jump
  const goto = async nextId => {
    // console.log('goto:', nextId)

    const nextChatData = Object.assign({}, json[nextId])

    const nextChatDatas = chatDatas.concat(nextChatData)
    setChatId(nextId)

    // Capped
    const _nextChatDatas = nextChatDatas.slice(nextChatDatas.length - 16, nextChatDatas.length)
    setChatDatas(_nextChatDatas)

    const delay = parseInt(nextChatData.typing)
    if (delay > 0) {
      return new Promise(_ => setTimeout(_, delay)).then(() => goto(nextChatData.jump))
    }

    return nextId
  }

  useEffect(() => {
    if (!answerPath) return
    setTypeAndJumpId(answerPath)
  }, [answerPath])

  // Next
  useEffect(
    () => {
      nextId && goto(nextId)
      // Scroll to bottom
      scrollToBottom(chatRef.current)
    },
    // eslint-disable-next-line
    [nextId]
  )

  // Typing Effect
  useEffect(() => {
    if (!typeAndJumpId) return

    // Wrong jump
    if (!json[typeAndJumpId]) {
      console.error(`Wrong jump ID : ${typeAndJumpId}`)
      return
    }

    // Will add typing msg
    const { uid, name, img, typing = isLocal ? 100 : 1000 } = json[typeAndJumpId]

    const typingChatData = getTypingChatData({ uid, name, img, jump: typeAndJumpId, typing })
    const typingId = '_typing_' + typeAndJumpId
    json[typingId] = typingChatData

    setNextId(typingId)
  }, [typeAndJumpId])

  // TODO : promote to quest class
  // Quest : Quiz, Interview, Game
  useEffect(
    () => {
      if (!quest) return
      ;(async () => {
        const nextId = await QuestRouter.route(json, quest)
        setTypeAndJumpId(nextId)
      })()
    },
    // eslint-disable-next-line
    [quest]
  )

  // Last line
  useEffect(() => {
    // Content type
    const { quiz, game, jump, gimmick, exit } = _.last(chatDatas)

    // Effect?
    if (gimmick) {
      // TODO : more gimmick
      setGimmick(gimmick)
    }

    // Quest?
    if (quiz || game) {
      setQuest({ id: questId, type: section, source: quiz || game })
      return
    }

    // Jump?
    if (jump) {
      setTypeAndJumpId(jump)
      return
    }

    // Exit?
    if (exit) {
      if (document.referrer.indexOf(window.location.origin) !== -1) {
        window.history.back()
      } else {
        window.location.href = window.location.origin
      }
    }

    // Scroll to bottom
    scrollToBottom(chatRef.current)
  }, [chatDatas, section, setGimmick, questId])

  useEffect(
    () => {
      if (!chatData.fill && !chatData.full) return

      const { jump } = chatData.fill || chatData.full
      if (answerResults.length > 0 && jump) {
        const answerResult = _.last(answerResults).result

        QuestModel.appendAnswerResult(answerResult ? 1 : 0)

        setAnswerPath(answerResult ? jump.corrects : jump.incorrects)
      }
    },
    // eslint-disable-next-line
    [answerResults]
  )

  // POST process ---------------------------------------------

  // Set active only last one
  chatDatas.forEach((item, index) => (item.active = index === chatDatas.length - 1))
  const chatData = _.last(chatDatas)

  // Add intercept
  if (chatData.id && chatData.id !== usedChatDataId) {
    // Block called
    setUsedChatDataId(chatData.id)

    // Inject handler
    addController(UserModel.firebase.uid, { json, goto, chatData })

    // Respond to each quest and sub task
    questResponder(chatData, { onBeginEachTask, onRetry })
  }

  // View  ---------------------------------------------

  // Avoid lost focus and lost scroll control
  const onBlur = e => e.target.focus()

  return (
    <Containerz>
      <Chatz ref={chatRef} onBlur={onBlur}>
        {chatDatas.map((item, index) => (
          <Msgz key={index} {...item} buttonRef={buttonRef} />
        ))}
        <Blankz />
      </Chatz>
    </Containerz>
  )
}

export default RBChatContainer
